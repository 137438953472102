html,
body,
#root {
  @apply bg-white;

  padding: 0;
  margin: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

a,
span.link {
  color: #67e0d1;
  cursor: pointer;
}

.app-container {
  @apply w-screen h-screen grid grid-cols-flowfi-base;
}

.app-container--condensed {
  @apply grid-cols-flowfi-condensed;
}

.app-navigation {
  @apply flex flex-col h-full p-3 bg-white border-r border-grayscale-300 relative shadow-flowfi;
}

.app-view-container {
  @apply w-full h-full p-10 pt-4 overflow-hidden overflow-y-scroll bg-grayscale-100;
}
