.user-menu {
  @apply relative mt-auto;
}

.user-menu__popover {
  @apply absolute z-10 bottom-0 flex flex-col gap-3 px-1 py-2 bg-white rounded-lg border border-grayscale-300 shadow-flowfi;

  width: 240px;
  left: calc(100% + 12px);
}

button.user-menu__button {
  @apply flex items-center gap-4 font-bold text-sm hover:text-primary;

  padding: 10px;
  border-radius: 10px;
}

button.user-menu__button--open {
  @apply text-primary;
}

button.user-menu__button--route-active {
  @apply bg-grayscale-100;
}

.user-menu__link {
  @apply text-sm text-grayscale-900 p-2 rounded-lg hover:bg-grayscale-100;
}

.user-menu__logout {
  @apply text-sm text-left p-2 text-error-500 hover:bg-grayscale-100;
}
