.control.has-icons-right .icon {
  height: 100%;
}
.login-logo {
  width: 100%;
  height: 100%;
}

img.image-fit {
  object-fit: cover;
}
.background-transparent {
  background-color: transparent;
}

.company-domain-label {
  position: absolute;
  top: 52%;
  font-size: 1.4rem;
  right: 10px;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 100;
}

.accounts-modal h3 {
  margin-bottom: 20px;
}
.keygrowth-metrics {
  border-radius: 10px;
  background-color: var(--background-button);
  padding: 15px;
}
.vl {
  border-left: 1px solid var(--vertical-line);
  height: 24px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid var(--font-main-white);
  border-radius: 50%;
  border-top: 4px solid var(--font-main-green);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.parent-img {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.parent-img-company {
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent-img-responsive {
  width: 20px;
  height: auto;
}

img.child-img-right {
  position: absolute;
  top: 60%;
  left: 60%;
  width: 50%;
}
img.child-img-right-company {
  position: absolute;
  top: 70%;
  left: 70%;
  width: 30%;
}

img.child-img-right.workspace-switcher-badge {
  position: absolute;
  top: 80%;
  left: 80%;
  width: 20%;
  max-width: 10px;
}

.flowfi-text-input.domain-input {
  padding-right: 92px;
}

.flowfi-text-input-label.domain-label {
  right: 19px;
  left: auto;
  bottom: 27%;
}

div.company-members-list-container {
  /* max-height: calc(100vh - 400px); */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
div.company-members-list-container ::-webkit-scrollbar {
  display: none;
}

:hover,
:focus {
  outline: none;
}

.cards-button-width {
  width: 10%;
}

.year-dot {
  background-color: rgba(57, 161, 234, 0.1);
  border-radius: 50%;
  justify-content: center;
  width: 50px;
  display: flex;
  height: 50px;
  align-items: center;
  flex-flow: column;
  color: #39a1ea;
}
.year-dot-number {
  font-weight: 600;
}
.sub-dot {
  background-color: var(--background-button);
  border-radius: 50%;
  justify-content: center;
  width: 50px;
  display: flex;
  height: 50px;
  align-items: center;
  flex-flow: column;
}
.insight-dot {
  border-radius: 50%;
  justify-content: center;
  width: 50px;
  display: flex;
  height: 50px;
  align-items: center;
  flex-flow: column;
  color: #39a1ea;
}

.search-modal-content {
  min-width: 50vw;
}
.search-result-section {
  height: 20vh;
}
.search-result-box {
  max-width: 250px;
  background: #2b3147;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  margin: 10px;
}

.search-results-hr {
  border: 1px solid #3a395a;
}

.is-full-height {
  height: 100%;
}

.is-full-width {
  width: 100%;
}

.card {
  @apply rounded-2xl;

  color: white;
}
.card .subtitle,
.card .title {
  color: white;
}
.card .hover-icon {
  height: 20px;
}

.top-nav-section {
  padding: 0 0 0 1rem;
}
.top-nav-section button.button.is-stripped {
  background-color: transparent;
  border: none;
  padding: 0;
}
.overflow-hidden {
  overflow: hidden;
}
.filter-applied {
  height: 8px;
  width: 8px;
  background-color: var(--font-main-blue);
  border-radius: 10px;
  position: absolute;
  left: 70%;
  bottom: 70%;
}

.runway-normal {
  width: 100%;
  height: 45px;
  color: #32dfc5;
  background: rgba(32, 145, 137, 0.1);
  border-radius: 8px;
}
.runway-warning {
  width: 100%;
  height: 45px;
  color: #e06767;
  background: rgba(224, 103, 103, 0.1);
  border-radius: 8px;
}

.insights-container {
  height: 70%;
  overflow-x: hidden;
  overflow-y: auto;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--background-tray);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.short-horizontal-separator {
  @apply py-8 relative;

  height: 2px;
}

.short-horizontal-separator::before {
  @apply w-10 absolute top-8 left-8;

  content: "";
  height: 2px;
  background-color: var(--font-main-gray);
}

.long-horizontal-separator {
  @apply my-5 w-full;
  border-bottom: 1px solid var(--font-main-gray);
}

.list-row:nth-child(odd) {
  background-color: var(--background-table-odd);
}

.list-row:nth-child(even) {
  background-color: var(--background-table-even);
}

.list-row:nth-child(n + 2) {
  margin-top: 1px;
}
