.user-icon {
  @apply flex items-center justify-center rounded-full font-bold bg-grayscale-500 text-white text-center;
}

.user-icon--sm {
  @apply w-6 h-6 text-xs;
}

.user-icon--md {
  @apply w-8 h-8 text-base;
}

.user-icon--lg {
  @apply w-12 h-12 text-xl;
}

.user-icon--xl {
  @apply w-16 h-16 text-4xl;
}
