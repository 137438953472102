.select-custom__button {
  background-color: #27293e;
  border-radius: 10px;
  width: 100%;
  height: 35px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -1px;
  font-size: 16px;
  color: inherit;
}

.select-custom__button:hover {
  border: 1px solid #67e0d1 !important;
  cursor: default;
}

.select-custom__dropdown {
  background-color: #27293e;
  border-radius: 10px;
  border: 1px solid #67e0d1;
  padding: 10px;
  position: absolute;
  width: auto;
  z-index: 1;
}
