.flowfi-breadcrumb {
  @apply mb-6;
  font-size: 1rem;
}

.flowfi-breadcrumb ul {
  @apply flex flex-row m-0 p-0;
}

.flowfi-breadcrumb__item {
  @apply mx-1 pl-3 pr-1;
}

.flowfi-breadcrumb__item:first-child {
  @apply ml-0 pl-0;
}

.flowfi-breadcrumb__item:not(:first-child)::before {
  @apply relative;
  content: "\0227B";
  color: var(--font-cta-green);
  left: -0.75rem;
}

.flowfi-breadcrumb__item a {
  color: var(--font-main-gray);
}

.flowfi-breadcrumb__item--active {
  /* placeholder */
}

.flowfi-breadcrumb__item--active a {
  @apply text-white;
}
