@media only screen and (max-width: 1439px) {
  input.is-large.custom-input,
  .flowfi input.input,
  .flowfi button.button.is-primary {
    font-size: 1vw;
  }
}
@media only screen and (max-width: 1023px) {
  .flowfi input.input,
  .flowfi button.button.is-primary {
    font-size: 2vw;
  }
  .flowfi-card .flowfi-card-title {
    font-size: 3vw;
  }
  .login-logo {
    max-width: 50%;
    max-height: 50%;
  }
  .font-heading {
    font-size: 4vw;
  }
  .percentile-tooltip {
    width: 30vw;
  }
}
