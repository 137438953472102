.toast--dark {
  background-color: var(--background-main-dark);
}
.toast--default {
  background-color: var(--background-main-lighter);
}
div.toast--info {
  background-color: var(--font-main-green);
  color: var(--background-button);
  border-radius: 8px;
}
div.toast--success {
  background-color: var(--font-main-green);
  color: var(--background-button);
  border-radius: 8px;
}
div.toast--warning {
  background-color: var(--font-warning);
  color: var(--background-button);
  border-radius: 8px;
}
div.toast--error {
  background-color: var(--font-warning);
  color: var(--background-button);
  border-radius: 8px;
}
.toast--body {
  font-size: var(--font-size-normal);
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Montserrat", "Arial", sans-serif;
}
