/* TODO: This needs additional styling to handle the height properly, 
as well as place the confirmation buttons */
.flowfi-modal__wrapper {
  @apply flex flex-col;
}

.flowfi-modal__wrapper > * {
  @apply bg-white;
}

.flowfi-modal__wrapper--footerless > *:last-child {
  @apply rounded-br-2xl rounded-bl-2xl;
}

.flowfi-modal__header {
  @apply flex flex-row items-center p-5 rounded-tl-xl rounded-tr-xl;
}

.flowfi-modal__close {
  @apply ml-auto p-2;
}

.flowfi-modal__body {
  @apply p-8 pt-0 flex-grow flex-shrink;
}

.flowfi-modal__footer {
  @apply m-0 p-0 rounded-bl-xl rounded-br-xl;
}

.flowfi-modal__footer--filled {
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
/* !important is used in a few spots due to inline styles being present */
.ReactModal__Content {
  transition: 0.2s transform, 0.2s opacity;
  transform: translateY(-50%);
  opacity: 0;
  min-width: 400px !important;
  min-height: 0 !important;
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  opacity: 1;
  transform: translateY(0);
}

.ReactModal__Content::-webkit-scrollbar {
  border-right: 10px solid transparent;
}

/* Mobile */
@media screen and (max-width: 768px) {
  .ReactModal__Content {
    min-width: 60vw !important;
    max-width: 80vw !important;
  }
}
