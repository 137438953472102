.search-card {
  min-width: 100px;
  width: 100%;
  color: #ffffff;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.search-container {
}
.search-results-content {
  background-color: #202538;
}
.search-results-container {
  background-color: #202538;
  margin: 1.5rem -1.5rem -1.5rem -1.5rem;
  padding: 1.5rem;
}
.flowfi-graph {
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 32px;
  height: 400px;
}
.display-data-container-xlarge {
  height: 600px;
  justify-content: center;
  flex-direction: column;
}
.display-data-container-large {
  height: 500px;
  justify-content: center;
  flex-direction: column;
}
.display-data-container {
  height: 300px;
  justify-content: center;
  flex-direction: column;
}
.progress-data-container {
  height: 80%;
}
.locked-overlay-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.locked-image {
  width: 100%;
  height: 100%;
  filter: blur(4px);
}
.locked-overlay {
  width: 100%;
  height: 100%;

  position: absolute;
}
.flowfi-card .flowfi-card-title {
  font-size: 1.5vw;
}
.flowfi-card .flowfi-card-title.big {
  font-size: 24px;
}
.flowfi-card .flowfi-card-hr {
  height: 1px;
  opacity: 50%;
}
.flowfi-card .flowfi-input {
  margin-right: 24px;
  margin-bottom: 12px;
}

.flowfi-profile-card {
  @apply py-4;
  background: inherit;
  box-shadow: none;
  color: inherit;
}
.flowfi-profile-card .card-content {
  padding-left: 0;
}
.flowfi-profile-card .media-content {
  margin-top: 6px;
}
.flowfi-profile-card .flowfi-profile-image {
  @apply w-full mr-4;
  max-height: 60px;
  max-width: 60px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
}
.flowfi-overlay-image {
  border-radius: 50%;
  width: 50%;
}
.flowfi-overlay-figure {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.flowfi-profile-card .flowfi-profile-name {
  font-size: 15px;
  color: inherit;
}
.flowfi-profile-card .flowfi-profile-email {
  font-size: 13px;
  color: inherit;
  opacity: 0.5;
}
.flowfi-profile-card.has-email-only {
  padding: 20px;
  margin-top: 5px;
}

.level,
.flowfi.flowfi-onboarding.flowfi-onboarding-main,
.flowfi.flowfi-onboarding.flowfi-onboarding-team,
.flowfi.flowfi-onboarding.flowfi-onboarding-accounts,
.flowfi.flowfi-onboarding.flowfi-onboarding-company,
.flowfi.flowfi-onboarding.flowfi-onboarding-people {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flowfi-page {
  @apply relative pb-12;
}

.flowfi-page-decorated {
  @apply mx-0;

  background-image: url("../assets/onboarding-bg-3.svg");
  background-size: 28vw;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
}

.flowfi-nav-logo {
  max-width: 120px;
  max-height: 30px;
}
.flowfi .flowfi-drop-menu .flowfi-drop-content {
  background: var(--background-main-light);
  border-radius: 8px;
  box-shadow: 0px 14px 50px 0px #000000 14%;
  padding: 8px;
}

.full-height {
  height: 100%;
}

.flowfi-badge {
  border-radius: 8px;
  padding: 7px 9px;
}

.flowfi-badge-green {
  background-color: rgba(50, 223, 197, 0.1);
  color: #67e0d1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flowfi-badge-default {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(33, 37, 53, 1);
  color: #4f516a;
  height: 8vh;
}

.limited-width-modal {
  max-width: 410px;
}
