.flowfi-mobile.menu {
  position: absolute;
  width: 100%;
  height: calc(100vh - 78px);
  top: 78px;
  left: 0;
  z-index: 1000;
  background: var(--background-main-dark);
  transition: 0.2s transform ease-in-out;
  transform: translateX(-100%);
}

.flowfi-mobile.menu.menu-visible {
  transform: translateX(0);
}

.flowfi-side-nav-highlight {
  transition: 0.2s background-color ease-in-out;
}

.flowfi-side-nav-link .flowfi-side-nav-highlight {
  height: 62px;
}

.flowfi-side-nav-link:hover .flowfi-side-nav-highlight {
  background-color: #32dfc5;
}

.flowfi-side-nav-link {
  @apply block my-4 mx-0 w-full;

  color: #8585a6 !important;
}

.flowfi-side-nav-link:hover {
  background-color: inherit !important;
}

.flowfi-side-nav-highlight {
  width: 4px;
}

.flowfi-side-nav-highlight.is-active {
  background: #32dfc5;
}

.flowfi-side-nav-link .flowfi-side-nav-icon .is-active,
.flowfi-side-nav-link .flowfi-side-nav-name.is-active {
  color: #32dfc5;
}
