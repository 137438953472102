div.dropdown-content {
  padding: 0;
  border-radius: 10px;
  width: 200px;
}

div.dropdown-content.revenue {
  padding: 0;
  border-radius: 10px;
  height: 200px;
  width: 200px;
}

div.dropdown-content.transaction-filter-back {
  background-color: transparent;
  padding: 0;
}
.transaction-card {
  height: 500px;
}
div.card.transaction-filter-card {
  height: 300px;
  background-color: var(--background-button);
}
div.dropdown-content.transaction-filter-card-container {
  width: 250px;
}
div.card.spend-filter-card {
  height: 350px;
  background-color: var(--background-button);
}
div.card-content {
  width: 100%;
  height: 85%;
  padding: 10px 25px;
  overflow-y: auto;
  overflow-x: hidden;
}
footer.card-footer.flow-footer {
  height: 15%;
  background-color: var(--background-tray);
  border: none;
}
button.card-footer-item.is-stripped.flow-footer-item {
  border: none;
  height: 100%;
}

.filter-close {
  z-index: 99;
  left: 9vw;
  top: 16px;
  background-color: #252833;
}
.filter-modal {
  width: 18vw;
  height: 60vh;
  background-color: var(--background-button);
  border-radius: 8px;
  overflow: hidden;
}

.flowfi-transaction-item {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  margin: 0 auto;
  position: relative;
}
.flowfi-transaction-initial {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: var(--input-primary);
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid var(--background-main-dark);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
