.month-picker-dropdown__header {
  min-width: 220px;
}

.month-picker-dropdown__header {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  grid-template-areas: "left year right";
  padding-bottom: 6px;
}

.month-picker-dropdown__page-button {
  background-color: inherit;
  border: none;
  display: flex;
  padding: 0 4px;
}

.month-picker-dropdown__page-button:hover {
  cursor: pointer;
}

.month-picker-dropdown__page-button-left {
  grid-area: left;
  justify-self: flex-start;
}

.month-picker-dropdown__page-button-right {
  grid-area: right;
  justify-self: flex-end;
}

.month-picker-dropdown__page-img {
  width: 9px;
  align-self: center;
  justify-content: center;
}

.month-picker-dropdown__page-img-left {
  transform: scaleX(-1);
}

.month-picker-dropdown__year {
  grid-area: year;
  justify-self: center;
  font-size: larger;
}

.month-picker-dropdown__months {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 1fr);
  gap: 1rem;
  grid-auto-flow: column;
}

.month-picker-dropdown__month-button {
  background-color: #393c58;
  border: 1px solid #393c58;
  border-radius: 4px;
  padding: 6px 0;
  display: flex;
  justify-content: center;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

.month-picker-dropdown__month-button:hover:not(.month-picker-dropdown__month-button:disabled) {
  cursor: pointer;
}

.month-picker-dropdown__month-button:disabled {
  color: gray;
}

.month-picker-dropdown__month-button--selected {
  background-color: rgba(103, 224, 209, 0.2);
  border: 1px solid #67e0d1;
}
