.flowfi-button {
  @apply flex bg-white text-white border cursor-pointer items-center text-center justify-center px-2 whitespace-nowrap;
  min-width: 50px;
  min-height: 32px;
  border-color: transparent;
  font-size: 1rem;
  font-family: inherit;
  padding-bottom: calc(0.5em - 1px);
  padding-top: calc(0.5em - 1px);
}

.flowfi-button--primary {
  @apply rounded-md;
  background-color: var(--font-main-blue);
  font-size: var(--font-size-normal);
}

.flowfi-button--alt-primary {
  @apply rounded-md;
  background: rgba(103, 224, 209, 0.1);
  color: #32dfc5;
  font-size: var(--font-size-normal);
}

.flowfi-button--alt-primary-active {
  border-color: #32dfc5;
}

.flowfi-button--secondary {
  background-color: #209189;
  color: #32dfc5;
  font-size: var(--font-size-normal);
}

.flowfi-button--alt-secondary {
  @apply text-white;
  font-size: var(--font-size-normal);
}

.flowfi-button--tertiary {
  @apply text-white rounded-2xl border-none;
  background-color: #212535;
  font-size: 100%;
}

.flowfi-button--default {
  @apply text-white border-none rounded-2xl;
  font-size: var(--font-size-normal);
  background-color: var(--background-tray);
}

.flowfi-button--default:active {
  @apply border-none rounded-2xl;
  font-size: var(--font-size-normal);
  background-color: var(--font-main-white);
  color: var(--background-tray);
}

/* TODO: the existence of this button implies we need to do an audit of the app */
.flowfi-button--action-items {
  @apply rounded-xl;
  background-color: #253c47;
  color: #67e0d1;
  font-size: var(--font-size-normal);
}

.flowfi-button--action-items[disabled] {
  color: #62d4c6;
}

.flowfi-button--warning {
  @apply bg-transparent rounded-2xl border-none;
  color: var(--font-warning);
  font-size: var(--font-size-normal);
}

.flowfi-button--warning[disabled] {
  @apply border-none rounded-2xl;
  color: var(--font-main-gray);
  font-size: var(--font-size-normal);
}

.flowfi-button--warning:hover {
  @apply outline-none shadow-none;
}

.flowfi-button--transparent,
.flowfi-button--transparent:hover {
  @apply bg-transparent;
}

.flowfi-button--stripped {
  @apply text-white bg-transparent bg-no-repeat border-none cursor-pointer overflow-hidden outline-none shadow-none;
}

.flowfi-button--stripped:hover {
  color: var(--font-main-green);
}

.flowfi-button--stripped-active {
  color: var(--font-main-green);
  background-color: none;
}

.flowfi-button--stripped-blue {
  @apply text-white bg-transparent bg-no-repeat border-none cursor-pointer overflow-hidden outline-none shadow-none;
  color: var(--font-main-blue);
}

.flowfi-button--stripped-red {
  @apply text-white bg-transparent bg-no-repeat border-none cursor-pointer overflow-hidden outline-none shadow-none;
  color: var(--background-notification);
}

.flowfi-button--link {
  @apply bg-transparent;
  color: #67e0d1;
  font-family: inherit;
  font-size: var(--font-size-normal);
}

.flowfi-button--wide {
  @apply w-full;
}

.flowfi-button--auto {
  @apply w-auto;
}

.flowfi-button--text-large {
  font-size: var(--font-size-heading);
}

.flowfi-button--text-normal {
  font-size: var(--font-size-normal);
}

.flowfi-button--text-small {
  font-size: 1.2rem;
}

.flowfi-button__icon-wrap {
  @apply flex justify-center overflow-ellipsis whitespace-nowrap;
}

.flowfi-button__icon-wrap * {
  @apply mr-4;
}

.flowfi-button__icon-wrap *:last-child {
  @apply mr-0;
}

.flowfi-button[disabled],
button[disabled] {
  @apply cursor-not-allowed opacity-50;
}

.flowfi-option-buttons {
  @apply flex flex-row mx-4;
}

.flowfi-option-buttons .flowfi-button {
  @apply rounded-none;
}

.flowfi-option-buttons .flowfi-button:first-child {
  @apply rounded-md rounded-tr-none rounded-br-none;
}

.flowfi-option-buttons .flowfi-button:last-child {
  @apply rounded-md rounded-tl-none rounded-bl-none;
}
