/* Changes the absolute positioning to account for,
 smaller padding in modal vs back side of card. Both should
 be standardized when the backs of cards *are* modals */
.confirm-buttons__modal {
  top: 2rem;
  width: calc(100% + calc(2rem * 2));
  left: calc(2rem * -1);
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.reports-modal__wrap {
  width: 400px;
}
