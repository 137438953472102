/* TODO: Organize these styles into more discrete files. */

.menu-list a {
  padding-left: 1px;
}
.flowfi .menu-list a:hover {
  background-color: transparent;
  color: #32dfc5;
}

span.tag:not(body).is-primary,
span.tag:not(body).is-primary:hover,
a.tag:not(body),
a.tag:not(body):hover,
.tag:not(body).is-delete:hover,
.tag:not(body).is-delete:focus {
  background-color: transparent;
  color: var(--font-cta-green);
  border: none;
  margin-bottom: 0;
}
span.tags.tag,
a.tags.tag,
div.tags {
  margin: 0;
  border: 1px solid var(--font-cta-green);
  border-radius: 10px;
}

.flowfi .select.is-primary select {
  background-color: #212535;
  color: white;
  border-radius: 10px;
  border: none;
  font-size: 1.4rem;
}

.flowfi-drop-menu {
  @apply text-xs;
  top: 25px;
  color: white;
  z-index: 99;
}
.select.flowfi-select {
  background-color: transparent;
  color: #fff;
  border: 1px solid rgba(193, 193, 209, 0.4);
  height: 55px;
  font-size: 1.4rem;
  line-height: 1.8rem;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  user-select: none;
}
.select.flowfi-select option {
  color: #000;
}
.select.flowfi-select.is-active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #3273dc;
}
.select.flowfi-select .flowfi-select-options {
  width: 100%;
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 1001;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 0 0 0.1em #3273dc;
  user-select: none;
}
.select.flowfi-select .flowfi-select-options .flowfi-select-option {
  height: 35px;
  position: relative;
  margin-bottom: 0;
}
.select.flowfi-select .flowfi-select-options .flowfi-select-option:hover {
  color: #32dfc5;
}
.flowfi-table
  .flowfi-table-header
  .flowfi-table-header-row
  .flowfi-table-header-cell {
  color: rgba(255, 255, 255, 0.5);
}

.flowfi-table .flowfi-table-body .flowfi-table-row {
  border-radius: 10px;
}
.flowfi-table .flowfi-table-body .flowfi-table-row:not(:last-child) {
  margin-bottom: 15px;
}

.custom-input {
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 0;
}
.custom-input:active {
  border: none;
  box-shadow: none;
}
.custom-input:focus {
  border: none;
  box-shadow: none;
}
input.is-large.custom-input {
  font-size: var(--font-size-heading);
  border: none;
}

.breadcrumb a {
  align-items: center;
  color: var(--font-main-gray);
  display: flex;
  justify-content: center;
  padding: 0 0.75em;
}
.breadcrumb li.is-active a {
  color: var(--font-main-white);
  cursor: default;
  pointer-events: none;
}
.breadcrumb li + li::before {
  color: var(--font-main-green);
  content: "\0002f";
}

div.tabs {
  overflow: hidden;
}
div.tabs ul {
  border: none;
}
div.tabs li.is-active button {
  background-color: Transparent;
  background-repeat: no-repeat;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  font-size: var(--font-size-normal);
  border-bottom-color: var(--font-cta-green);
  color: var(--font-cta-green);
}
div.tabs li.is-active a {
  border-bottom: 2px solid;
  border-bottom-color: var(--font-cta-green);
  color: var(--font-cta-green);
}
div.tabs a {
  border-bottom: 2px solid var(--font-main-gray);
}
div.tabs li button {
  width: 100%;
  height: 40px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border-top: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  border-bottom-style: solid;
  font-size: var(--font-size-normal);
  color: var(--font-main-gray);
  border-bottom-color: var(--font-main-gray);
}

.react-tooltip {
  width: 20vw;
}
.percentile-tooltip {
  width: 10vw;
}
.graph-tooltip,
.graph-tooltip-no-verify,
.graph-tooltip-no-verify-alt {
  background-color: var(--background-button);
}
.graph-tooltip-no-verify::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--background-button) transparent transparent transparent;
}
.graph-tooltip-no-verify-alt::after {
  content: " ";
  position: absolute;
  top: 75%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--background-button) transparent transparent transparent;
}
.graph-tooltip-no-verify-dot {
  content: " ";
  position: absolute;
  top: 108%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.graph-tooltip::after {
  content: " ";
  position: absolute;
  top: 72%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--background-button) transparent transparent transparent;
}
.tooltip-dot {
  content: " ";
  position: absolute;
  top: 106%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.is-button {
  cursor: pointer;
}

legend {
  border: 1px var(--font-main-green) solid;
  border-radius: 50px;
  padding: 5%;
  text-align: center;
}
