.checklist__header {
  @apply flex flex-row;
}

.checklist__header h3 {
  @apply self-center;
  color: var(--font-main-light);
}

.checklist__header .flowfi-checkbox__wrapper {
  @apply ml-auto;
}

.checklist__checks {
  @apply rounded-xl p-3.5 mt-3.5 flex flex-col;
  background-color: var(--background-control);
}
