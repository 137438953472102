.flowfi-checkbox__wrapper {
  @apply flex flex-row items-center;
}

.flowfi-checkbox {
  height: 20px;
  width: 0px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.flowfi-checkbox + label {
  position: relative;
  @apply py-1;
}

.flowfi-checkbox + label::before {
  content: "";
  position: relative;
  top: 4px;
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: var(--check-bg);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  border-radius: 0.25rem;
}

.flowfi-checkbox:checked + label::before {
  background: var(--check-checked-bg);
  box-shadow: none;
}

.flowfi-checkbox:checked + label::after {
  content: "";
  position: absolute;
  top: 13px;
  left: 4px;
  border-left: 2px solid var(--check-indicator);
  border-bottom: 2px solid var(--check-indicator);
  border-radius: 2px;
  height: 6px;
  width: 13px;
  transform: rotate(-45deg);
}

.flowfi-checkbox.indeterminate + label::after {
  content: "";
  position: absolute;
  top: 16px;
  left: 4px;
  border: 0;
  height: 2px;
  width: 12px;
  background: var(--check-indicator);
  transform: rotate(0);
}

.flowfi-checkbox.indeterminate + label::before {
  background: var(--check-checked-bg);
}

/* disabling outline for now since we don't have styles */
/* .flowfi-checkbox:focus + label::before {
  outline: #5d9dd5 solid 1px;
  box-shadow: 0 0px 8px #5e9ed6;
} */

.flowfi-checkbox:disabled + label {
  color: #575757;
}

.flowfi-checkbox:disabled + label::before {
  background: #ddd;
}

.flowfi-checkbox__label {
  @apply text-base;
}

/* The current comps support a dark theme, so here's a rough sketch */
.flowfi-checkbox.theme-inverted + label::before {
  background: var(--check-bg-inverse);
}

.flowfi-checkbox.theme-inverted:checked + label::before {
  background: var(--check-checked-bg-inverse);
}

.flowfi-checkbox.theme-inverted:checked + label::after {
  border-left: 2px solid var(--check-indicator-inverse);
  border-bottom: 2px solid var(--check-indicator-inverse);
}

.flowfi-checkbox.indeterminate.theme-inverted + label::after {
  background: var(--check-indicator-inverse);
}

.flowfi-checkbox.indeterminate.theme-inverted + label::before {
  background: var(--check-checked-bg-inverse);
}
