.flowfi-tags {
  @apply flex flex-row flex-wrap;
}

.flowfi-tag {
  @apply flex flex-row items-center py-2 px-4 mr-4 mb-4 whitespace-nowrap border border-white rounded-full;
}

.flowfi-tag__icon {
  @apply ml-2;

  min-width: 16px;
}
