.flowfi-modal-wrapper {
  background-color: rgba(13, 15, 27, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
}
.flowfi-modal-wrapper .flowfi-modal {
  width: 412px;
  z-index: 1002;
  border-radius: 10px;
  padding: 40px 32.5px;
  position: relative;
}
.flowfi-modal-wrapper .flowfi-modal-close {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  background-color: #252833;
  cursor: pointer;
}
.flowfi-modal-wrapper .flowfi-modal-close img {
  width: 30px;
  height: 20px;
  max-height: 20px;
}

.flowfi-modal {
  padding: 2vw;
  border-radius: 1%;
  overflow: auto;
}
.flowfi-modal.overridden-width {
  width: 30vw;
}
.flowfi-modal.overridden-height {
  height: 60vh;
}

.flowfi-modal-close {
  z-index: 99;
  left: 15vw;
  top: 16px;
  background-color: #252833;
}

.search-modal-close {
  z-index: 99;
  left: 25vw;
  top: 16px;
  background-color: #252833;
}

@media only screen and (max-width: 1023px) {
  .flowfi-modal {
    padding: 2vw;
    border-radius: 1%;
    overflow: hidden;
  }
  .flowfi-modal.overridden-width {
    width: 80vw;
  }
  .flowfi-modal-close {
    z-index: 99;
    left: 40vw;
    top: 16px;
    background-color: #252833;
  }
  .search-modal-content {
    width: 80vw;
  }
  .search-modal-close {
    z-index: 99;
    left: 40vw;
    top: 16px;
    background-color: #252833;
  }
}
