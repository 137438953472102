/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");

/* Other imports */
@import "./base.css";
@import "./fonts.css";
@import "./general.css";
@import "./toast.css";
@import "./buttons.css";
/* @import "./inputs.css"; */
@import "./tags.css";
@import "./breadcrumbs.css";
@import "./controls.css";
@import "./flow.css";
@import "./mediaquery.css";
@import "./modals.css";
@import "./dropdown.css";
@import "./animation.css";
@import "./lib/simplebar.css";

/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* The base layer is where we can set styles for bare HTML tags (e.g. h1, a, p, etc) */
@layer base {
  html {
    font-size: 16px;
  }

  body {
    @apply m-0 text-grayscale-900;
    font-family: "Manrope", "Helvetica", "Arial", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-extrabold;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  a {
    @apply text-primary;
  }

  p {
    @apply text-grayscale-600 text-sm;
  }

  label {
    @apply block text-xs text-grayscale-500;
  }

  label + * {
    @apply mt-1;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  textarea {
    @apply p-2 w-full border rounded-lg border-grayscale-300;
  }

  input::placeholder {
    @apply text-grayscale-400;
  }
}

:root {
  --background-main-dark: #121626;
  --background-main-lighter: #191d2d;
  --background-main-light: #1d2133;
  --background-cta-green: #209189;
  --background-cta-green-opaque: rgba(103, 224, 209, 0.5);
  --background-cta-green-opaque-light: rgba(103, 224, 209, 0.16);
  --background-card: #212049;
  --background-button: #212535;
  --background-tray: #262b3d;
  --background-button-hover: #283845;
  --background-locked: #2c3144;
  --background-warning: rgba(239, 130, 106, 0.1);
  --background-notification: #c53054;
  --background-table-odd: #272942;
  --background-table-even: #2f314e;
  --background-control: #27293e;
  --background-active-green: #15353d;
  --font-main-white: #fff;
  --font-main-light: #b8b9bf;
  --font-main-gray: #8585a6;
  --font-main-blue: #2154b8;
  --font-main-green: #67e0d1;
  --font-cta-green: #32dfc5;
  --font-warning: #e06767;
  --font-subscriptions-decrease: #39a1ea;
  --font-subscriptions-increase: #ff9f59;
  --font-subscriptions-same: #d6e900;
  /* TODO: These need to be shifted to rems & anything that scales to the viewport should be scrubbed */
  --font-size-normal: 0.875rem;
  --font-size-heading: 1.125rem;

  --vertical-line: #4f516a;

  --placeholder: rgba(--color: #fff, --alpha: 0.5);

  --button_primary: #2154b8;
  --button_secondary: #48a29f;

  --input-primary: #5ec8be;

  /* checkboxes/radios */
  --check-bg: #2c2e46;
  --check-checked-bg: #2154b8;
  --check-indicator: #fff;
  --check-bg-inverse: #fff;
  --check-checked-bg-inverse: #67e0d1;
  --check-indicator-inverse: #2c2e46;

  --scaleFactor: 1.6;
}
