.confirm-buttons__relative-wrapper {
  position: relative;
  height: 50px;
}

.confirm-buttons {
  @apply flex flex-row;
  height: 50px;
  overflow: hidden;
}

.confirm-buttons__button {
  @apply text-center;

  flex-grow: 1;
  padding: 1rem;
  font-size: 14px;
  font-weight: bold;
}

.confirm-buttons__button:disabled {
  color: var(--font-main-gray);
  cursor: not-allowed;
}

.confirm-buttons__button-left {
  @apply rounded-bl-xl;

  background-color: var(--background-main-light);
}

.confirm-buttons__button-right {
  @apply rounded-br-xl;

  color: var(--font-main-green);
  background-color: var(--background-cta-green-opaque-light);
}
