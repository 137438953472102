@font-face {
  font-family: "Cera Pro";
  src: url("../assets/fonts/CeraPro-Regular.woff") format("woff"),
    url("../assets/fonts/CeraPro-Regular.ttf") format("truetype"),
    url("../assets/fonts/CeraPro-Regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Cera Pro - Black";
  src: url("../assets/fonts/CeraPro-Black.woff") format("woff"),
    url("../assets/fonts/CeraPro-Black.ttf") format("truetype"),
    url("../assets/fonts/CeraPro-Black.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Cera Pro - Bold";
  src: url("../assets/fonts/CeraPro-Bold.woff") format("woff"),
    url("../assets/fonts/CeraPro-Bold.ttf") format("truetype"),
    url("../assets/fonts/CeraPro-Bold.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Cera Pro - Light";
  src: url("../assets/fonts/CeraPro-Light.woff") format("woff"),
    url("../assets/fonts/CeraPro-Light.ttf") format("truetype"),
    url("../assets/fonts/CeraPro-Light.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Cera Pro - Thin";
  src: url("../assets/fonts/CeraPro-Thin.woff") format("woff"),
    url("../assets/fonts/CeraPro-Thin.ttf") format("truetype"),
    url("../assets/fonts/CeraPro-Thin.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Cera Pro - Italic";
  src: url("../assets/fonts/CeraPro-Italic.woff") format("woff"),
    url("../assets/fonts/CeraPro-Italic.ttf") format("truetype"),
    url("../assets/fonts/CeraPro-Italic.eot") format("embedded-opentype");
}

.font-green {
  color: var(--font-main-green);
}

.font-blue {
  color: var(--font-main-blue);
}
.font-black {
  color: #000000;
}
.font-gray {
  color: var(--font-main-gray);
}
.font-white {
  color: var(--font-main-white);
}
.font-orange {
  color: var(--font-warning);
}
.font-heading {
  font-size: var(--font-size-heading);
}
.font-normal {
  font-size: var(--font-size-normal);
}
.font-bold {
  font-weight: bold;
}
.font-900 {
  font-weight: 900;
}
.font-responsive {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
}
.font-capitalize {
  text-transform: capitalize;
}
