.flowfi-import-progress-bar .import-loader img {
  max-height: 18px;
  max-width: 18px;
  position: relative;
  top: 1px;
}

.flowfi-import-progress-bar-card .flowfi-progress-bar-label span {
  font-size: 1rem;
}

.flowfi-top-nav {
  @apply flex flex-row mb-4;
}

.top-nav__buttons {
  @apply appearance-none flex ml-auto;
}

.top-nav__buttons button {
  @apply ml-5;
}

.flowfi-show-notifications {
  @apply hidden lg:block;
}
