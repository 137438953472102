.tab-bar {
  @apply flex flex-row;
}

.tab-bar__tab {
  @apply flex-grow text-center p-4;
  border-bottom: 2px solid var(--font-main-gray);
  color: var(--font-main-light);
}

.tab-bar__tab:disabled {
  cursor: not-allowed;
}

.tab-bar__tab--active {
  color: var(--font-main-white);
  border-bottom: 2px solid var(--font-main-green);
}
