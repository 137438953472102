.app-navigation:hover .navigation-toggle {
  @apply opacity-100;
}

.navigation-toggle {
  @apply opacity-0 flex items-center justify-center w-6 h-6 rounded-full bg-white border-2 border-grayscale-300 cursor-pointer absolute transition-opacity ease-in-out;
  top: 26px;
  right: -12px;
}

.navigation-toggle__icon {
}

.navigation-items {
  @apply flex flex-col gap-3;
}

.navigation-items .navigation-item__link {
  padding: 10px;
}

.navigation-item {
}

.navigation-item--collapsed .navigation-item__link {
  @apply gap-0;
}

/* this feels bad, but the badge doesn't care about collapsed states */
.navigation-item--collapsed .badge {
  @apply relative -top-1 -left-1 border border-white;
}

.navigation-item__link {
  @apply flex flex-row items-center gap-2 font-bold text-grayscale-900 hover:text-primary;

  padding: 14px;
  border-radius: 10px;
}

.navigation-item__link--active {
  @apply bg-grayscale-100 text-primary;
}
